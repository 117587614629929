import React from "react";

function Privacy(props) {
    return (
        <>
            <h1>PRIVACY POLICY</h1>
            <p class="has-line-data" data-line-start="2" data-line-end="3">
                Effective date: 04/19/2021
            </p>
            <ol>
                <li class="has-line-data" data-line-start="4" data-line-end="6">
                    <strong>Introduction</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="6" data-line-end="7">
                Welcome to <strong>Elixir Labs Inc</strong>.
            </p>
            <p class="has-line-data" data-line-start="8" data-line-end="9">
                Elixir Labs Inc (&quot;us&quot;, &quot;we&quot;, or
                &quot;our&quot;) operates{" "}
                <a href="https://elixirlabs.org">https://elixirlabs.org</a>,{" "}
                <a href="https://vaccinenotifications.org">
                    https://vaccinenotifications.org
                </a>{" "}
                and Vaccine Notifications mobile application (hereinafter
                referred to as &quot; <strong>Service</strong>&quot;).
            </p>
            <p class="has-line-data" data-line-start="10" data-line-end="11">
                Our Privacy Policy governs your visit to{" "}
                <a href="https://elixirlabs.org">https://elixirlabs.org</a>,{" "}
                <a href="https://vaccinenotifications.org">
                    https://vaccinenotifications.org
                </a>{" "}
                and Vaccine Notifications mobile application, and explains how
                we collect, safeguard and disclose information that results from
                your use of our Service.
            </p>
            <p class="has-line-data" data-line-start="12" data-line-end="13">
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
            </p>
            <p class="has-line-data" data-line-start="14" data-line-end="15">
                Our Terms and Conditions (&quot; <strong>Terms</strong>&quot;)
                govern all use of our Service and together with the Privacy
                Policy constitutes your agreement with us (&quot;{" "}
                <strong>agreement</strong>&quot;).
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="16"
                    data-line-end="18"
                >
                    <strong>Definitions</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="18" data-line-end="19">
                **SERVICE ** means the{" "}
                <a href="https://elixirlabs.org">https://elixirlabs.org</a>{" "}
                website,{" "}
                <a href="https://vaccinenotifications.org">
                    https://vaccinenotifications.org
                </a>{" "}
                website and Vaccine Notifications mobile application operated by
                Elixir Labs Inc.
            </p>
            <p class="has-line-data" data-line-start="20" data-line-end="21">
                <strong>PERSONAL DATA</strong> means data about a living
                individual who can be identified from those data (or from those
                and other information either in our possession or likely to come
                into our possession).
            </p>
            <p class="has-line-data" data-line-start="22" data-line-end="23">
                <strong>USAGE DATA</strong> is data collected automatically
                either generated by the use of Service or from Service
                infrastructure itself (for example, the duration of a page
                visit).
            </p>
            <p class="has-line-data" data-line-start="24" data-line-end="25">
                <strong>COOKIES</strong> are small files stored on your device
                (computer or mobile device).
            </p>
            <p class="has-line-data" data-line-start="26" data-line-end="27">
                <strong>DATA CONTROLLER</strong> means a natural or legal person
                who (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any
                personal data are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your data.
            </p>
            <p class="has-line-data" data-line-start="28" data-line-end="29">
                <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means
                any natural or legal person who processes the data on behalf of
                the Data Controller. We may use the services of various Service
                Providers in order to process your data more effectively.
            </p>
            <p class="has-line-data" data-line-start="30" data-line-end="31">
                **DATA SUBJECT ** is any living individual who is the subject of
                Personal Data.
            </p>
            <p class="has-line-data" data-line-start="32" data-line-end="33">
                **THE USER ** is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="34"
                    data-line-end="36"
                >
                    <strong>Information Collection and Use</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="36" data-line-end="37">
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="38"
                    data-line-end="40"
                >
                    <strong>Types of Data Collected</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="40" data-line-end="41">
                <strong>Personal Data</strong>
            </p>
            <p class="has-line-data" data-line-start="42" data-line-end="43">
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (&quot; <strong>Personal Data</strong>
                &quot;). Personally identifiable information may include, but is
                not limited to:
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="44"
                    data-line-end="46"
                >
                    Email address
                </li>
            </ol>
            <p class="has-line-data" data-line-start="46" data-line-end="47">
                <strong>Usage Data</strong>
            </p>
            <p class="has-line-data" data-line-start="48" data-line-end="49">
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through a
                mobile device (&quot; <strong>Usage Data</strong>&quot;).
            </p>
            <p class="has-line-data" data-line-start="50" data-line-end="51">
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
            </p>
            <p class="has-line-data" data-line-start="52" data-line-end="53">
                When you access Service with a mobile device, this Usage Data
                may include information such as the type of mobile device you
                use, your mobile device unique ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
            </p>
            <p class="has-line-data" data-line-start="54" data-line-end="55">
                <strong>Location Data</strong>
            </p>
            <p class="has-line-data" data-line-start="56" data-line-end="57">
                We may use and store information about your location if you give
                us permission to do so (&quot; <strong>Location Data</strong>
                &quot;). We use this data to provide features of our Service, to
                improve and customize our Service.
            </p>
            <p class="has-line-data" data-line-start="58" data-line-end="59">
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
            </p>
            <p class="has-line-data" data-line-start="60" data-line-end="61">
                <strong>Tracking Cookies Data</strong>
            </p>
            <p class="has-line-data" data-line-start="62" data-line-end="63">
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
            </p>
            <p class="has-line-data" data-line-start="64" data-line-end="65">
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
            </p>
            <p class="has-line-data" data-line-start="66" data-line-end="67">
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
            </p>
            <p class="has-line-data" data-line-start="68" data-line-end="69">
                Examples of Cookies we use:
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="70"
                    data-line-end="72"
                >
                    <p
                        class="has-line-data"
                        data-line-start="70"
                        data-line-end="71"
                    >
                        <strong>Session Cookies:</strong> We use Session Cookies
                        to operate our Service.
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="72"
                    data-line-end="74"
                >
                    <p
                        class="has-line-data"
                        data-line-start="72"
                        data-line-end="73"
                    >
                        <strong>Preference Cookies:</strong> We use Preference
                        Cookies to remember your preferences and various
                        settings.
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="74"
                    data-line-end="76"
                >
                    <p
                        class="has-line-data"
                        data-line-start="74"
                        data-line-end="75"
                    >
                        <strong>Security Cookies:</strong> We use Security
                        Cookies for security purposes.
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="76"
                    data-line-end="78"
                >
                    <p
                        class="has-line-data"
                        data-line-start="76"
                        data-line-end="77"
                    >
                        <strong>Advertising Cookies:</strong> Advertising
                        Cookies are used to serve you with advertisements that
                        may be relevant to you and your interests.
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="78"
                    data-line-end="80"
                >
                    <p
                        class="has-line-data"
                        data-line-start="78"
                        data-line-end="79"
                    >
                        <strong>Use of Data</strong>
                    </p>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="80" data-line-end="81">
                Elixir Labs Inc uses the collected data for various purposes:
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="82"
                    data-line-end="84"
                >
                    <p
                        class="has-line-data"
                        data-line-start="82"
                        data-line-end="83"
                    >
                        to provide and maintain our Service;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="84"
                    data-line-end="86"
                >
                    <p
                        class="has-line-data"
                        data-line-start="84"
                        data-line-end="85"
                    >
                        to notify you about changes to our Service;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="86"
                    data-line-end="88"
                >
                    <p
                        class="has-line-data"
                        data-line-start="86"
                        data-line-end="87"
                    >
                        to allow you to participate in interactive features of
                        our Service when you choose to do so;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="88"
                    data-line-end="90"
                >
                    <p
                        class="has-line-data"
                        data-line-start="88"
                        data-line-end="89"
                    >
                        to provide customer support;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="90"
                    data-line-end="92"
                >
                    <p
                        class="has-line-data"
                        data-line-start="90"
                        data-line-end="91"
                    >
                        to gather analysis or valuable information so that we
                        can improve our Service;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="92"
                    data-line-end="94"
                >
                    <p
                        class="has-line-data"
                        data-line-start="92"
                        data-line-end="93"
                    >
                        to monitor the usage of our Service;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="94"
                    data-line-end="96"
                >
                    <p
                        class="has-line-data"
                        data-line-start="94"
                        data-line-end="95"
                    >
                        to detect, prevent and address technical issues;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="96"
                    data-line-end="98"
                >
                    <p
                        class="has-line-data"
                        data-line-start="96"
                        data-line-end="97"
                    >
                        to fulfill any other purpose for which you provide it;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="98"
                    data-line-end="100"
                >
                    <p
                        class="has-line-data"
                        data-line-start="98"
                        data-line-end="99"
                    >
                        to carry out our obligations and enforce our rights
                        arising from any contracts entered into between you and
                        us, including for billing and collection;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="100"
                    data-line-end="102"
                >
                    <p
                        class="has-line-data"
                        data-line-start="100"
                        data-line-end="101"
                    >
                        to provide you with notices about your account and/or
                        subscription, including expiration and renewal notices,
                        email-instructions, etc.;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="102"
                    data-line-end="104"
                >
                    <p
                        class="has-line-data"
                        data-line-start="102"
                        data-line-end="103"
                    >
                        to provide you with news, special offers and general
                        information about other goods, services and events which
                        we offer that are similar to those that you have already
                        purchased or enquired about unless you have opted not to
                        receive such information;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="104"
                    data-line-end="106"
                >
                    <p
                        class="has-line-data"
                        data-line-start="104"
                        data-line-end="105"
                    >
                        in any other way we may describe when you provide the
                        information;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="106"
                    data-line-end="108"
                >
                    <p
                        class="has-line-data"
                        data-line-start="106"
                        data-line-end="107"
                    >
                        for any other purpose with your consent.
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="108"
                    data-line-end="110"
                >
                    <p
                        class="has-line-data"
                        data-line-start="108"
                        data-line-end="109"
                    >
                        <strong>Retention of Data</strong>
                    </p>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="110" data-line-end="111">
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p class="has-line-data" data-line-start="112" data-line-end="113">
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="114"
                    data-line-end="116"
                >
                    <strong>Transfer of Data</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="116" data-line-end="117">
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
            </p>
            <p class="has-line-data" data-line-start="118" data-line-end="119">
                If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to United States and process it there.
            </p>
            <p class="has-line-data" data-line-start="120" data-line-end="121">
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
            </p>
            <p class="has-line-data" data-line-start="122" data-line-end="123">
                Elixir Labs Inc will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organisation or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="124"
                    data-line-end="126"
                >
                    <strong>Disclosure of Data</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="126" data-line-end="127">
                We may disclose personal information that we collect, or you
                provide:
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="128"
                    data-line-end="130"
                >
                    <strong>Disclosure for Law Enforcement.</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="130" data-line-end="131">
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="132"
                    data-line-end="134"
                >
                    <strong>Business Transaction.</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="134" data-line-end="135">
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="136"
                    data-line-end="138"
                >
                    <p
                        class="has-line-data"
                        data-line-start="136"
                        data-line-end="137"
                    >
                        <strong>
                            Other cases. We may disclose your information also:
                        </strong>
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="138"
                    data-line-end="140"
                >
                    <p
                        class="has-line-data"
                        data-line-start="138"
                        data-line-end="139"
                    >
                        to fulfill the purpose for which you provide it;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="140"
                    data-line-end="142"
                >
                    <p
                        class="has-line-data"
                        data-line-start="140"
                        data-line-end="141"
                    >
                        with your consent in any other cases;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="142"
                    data-line-end="144"
                >
                    <p
                        class="has-line-data"
                        data-line-start="142"
                        data-line-end="143"
                    >
                        if we believe disclosure is necessary or appropriate to
                        protect the rights, property, or safety of the Company,
                        our customers, or others.
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="144"
                    data-line-end="146"
                >
                    <p
                        class="has-line-data"
                        data-line-start="144"
                        data-line-end="145"
                    >
                        <strong>Security of Data</strong>
                    </p>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="146" data-line-end="147">
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="148"
                    data-line-end="150"
                >
                    <strong>
                        Your Data Protection Rights Under General Data
                        Protection Regulation (GDPR)
                    </strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="150" data-line-end="151">
                If you are a resident of the European Union (EU) and European
                Economic Area (EEA), you have certain data protection rights,
                covered by GDPR. – See more at{" "}
                <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                    https://eur-lex.europa.eu/eli/reg/2016/679/oj
                </a>
            </p>
            <p class="has-line-data" data-line-start="152" data-line-end="153">
                We aim to take reasonable steps to allow you to correct, amend,
                delete, or limit the use of your Personal Data.
            </p>
            <p class="has-line-data" data-line-start="154" data-line-end="155">
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please email
                us at{" "}
                <a href="mailto:elixirlabsorg@gmail.com">
                    elixirlabsorg@gmail.com
                </a>
                .
            </p>
            <p class="has-line-data" data-line-start="156" data-line-end="157">
                In certain circumstances, you have the following data protection
                rights:
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="158"
                    data-line-end="160"
                >
                    <p
                        class="has-line-data"
                        data-line-start="158"
                        data-line-end="159"
                    >
                        the right to access, update or to delete the information
                        we have on you;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="160"
                    data-line-end="162"
                >
                    <p
                        class="has-line-data"
                        data-line-start="160"
                        data-line-end="161"
                    >
                        the right of rectification. You have the right to have
                        your information rectified if that information is
                        inaccurate or incomplete;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="162"
                    data-line-end="164"
                >
                    <p
                        class="has-line-data"
                        data-line-start="162"
                        data-line-end="163"
                    >
                        the right to object. You have the right to object to our
                        processing of your Personal Data;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="164"
                    data-line-end="166"
                >
                    <p
                        class="has-line-data"
                        data-line-start="164"
                        data-line-end="165"
                    >
                        the right of restriction. You have the right to request
                        that we restrict the processing of your personal
                        information;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="166"
                    data-line-end="168"
                >
                    <p
                        class="has-line-data"
                        data-line-start="166"
                        data-line-end="167"
                    >
                        the right to data portability. You have the right to be
                        provided with a copy of your Personal Data in a
                        structured, machine-readable and commonly used format;
                    </p>
                </li>
                <li
                    class="has-line-data"
                    data-line-start="168"
                    data-line-end="170"
                >
                    <p
                        class="has-line-data"
                        data-line-start="168"
                        data-line-end="169"
                    >
                        the right to withdraw consent. You also have the right
                        to withdraw your consent at any time where we rely on
                        your consent to process your personal information;
                    </p>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="170" data-line-end="171">
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note, we may not able to
                provide Service without some necessary data.
            </p>
            <p class="has-line-data" data-line-start="172" data-line-end="173">
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="174"
                    data-line-end="176"
                >
                    <strong>Service Providers</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="176" data-line-end="177">
                We may employ third party companies and individuals to
                facilitate our Service (&quot;{" "}
                <strong>Service Providers</strong>&quot;), provide Service on
                our behalf, perform Service-related services or assist us in
                analysing how our Service is used.
            </p>
            <p class="has-line-data" data-line-start="178" data-line-end="179">
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="180"
                    data-line-end="182"
                >
                    <strong>Analytics</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="182" data-line-end="183">
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
            </p>
            <p class="has-line-data" data-line-start="184" data-line-end="185">
                <strong>Google Analytics</strong>
            </p>
            <p class="has-line-data" data-line-start="186" data-line-end="187">
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
            </p>
            <p class="has-line-data" data-line-start="188" data-line-end="189">
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                </a>
            </p>
            <p class="has-line-data" data-line-start="190" data-line-end="191">
                We also encourage you to review the Google's policy for
                safeguarding your data:{" "}
                <a href="https://support.google.com/analytics/answer/6004245">
                    https://support.google.com/analytics/answer/6004245
                </a>
                .
            </p>
            <p class="has-line-data" data-line-start="192" data-line-end="193">
                <strong>Firebase</strong>
            </p>
            <p class="has-line-data" data-line-start="194" data-line-end="195">
                Firebase is analytics service provided by Google Inc.
            </p>
            <p class="has-line-data" data-line-start="196" data-line-end="197">
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                </a>
            </p>
            <p class="has-line-data" data-line-start="198" data-line-end="199">
                For more information on what type of information Firebase
                collects, please visit the Google Privacy Terms web page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                </a>
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="200"
                    data-line-end="202"
                >
                    <strong>CI/CD tools</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="202" data-line-end="203">
                We may use third-party Service Providers to automate the
                development process of our Service.
            </p>
            <p class="has-line-data" data-line-start="204" data-line-end="205">
                <strong>GitHub</strong>
            </p>
            <p class="has-line-data" data-line-start="206" data-line-end="207">
                GitHub is provided by GitHub, Inc.
            </p>
            <p class="has-line-data" data-line-start="208" data-line-end="209">
                GitHub is a development platform to host and review code, manage
                projects, and build software.
            </p>
            <p class="has-line-data" data-line-start="210" data-line-end="211">
                For more information on what data GitHub collects for what
                purpose and how the protection of the data is ensured, please
                visit GitHub Privacy Policy page:
                <a href="https://help.github.com/en/articles/github-privacy-statement">
                    https://help.github.com/en/articles/github-privacy-statement.
                </a>
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="212"
                    data-line-end="214"
                >
                    <strong>Advertising</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="214" data-line-end="215">
                We may use third-party Service Providers to show advertisements
                to you to help support and maintain our Service.
            </p>
            <p class="has-line-data" data-line-start="216" data-line-end="217">
                <strong>Google AdSense DoubleClick Cookie</strong>
            </p>
            <p class="has-line-data" data-line-start="218" data-line-end="219">
                Google, as a third party vendor, uses cookies to serve ads on
                our Service. Google's use of the DoubleClick cookie enables it
                and its partners to serve ads to our users based on their visit
                to our Service or other websites on the Internet.
            </p>
            <p class="has-line-data" data-line-start="220" data-line-end="221">
                You may opt out of the use of the DoubleClick Cookie for
                interest-based advertising by visiting the Google Ads Settings
                web page:{" "}
                <a href="http://www.google.com/ads/preferences/">
                    http://www.google.com/ads/preferences/
                </a>
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="222"
                    data-line-end="224"
                >
                    <strong>Behavioral Remarketing</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="224" data-line-end="225">
                Elixir Labs Inc uses remarketing services to advertise on third
                party websites to you after you visited our Service. We and our
                third-party vendors use cookies to inform, optimise and serve
                ads based on your past visits to our Service.
            </p>
            <p class="has-line-data" data-line-start="226" data-line-end="227">
                <strong>Google Ads (AdWords)</strong>
            </p>
            <p class="has-line-data" data-line-start="228" data-line-end="229">
                Google Ads (AdWords) remarketing service is provided by Google
                Inc.
            </p>
            <p class="has-line-data" data-line-start="230" data-line-end="231">
                You can opt-out of Google Analytics for Display Advertising and
                customize the Google Display Network ads by visiting the Google
                Ads Settings page:{" "}
                <a href="http://www.google.com/settings/ads">
                    http://www.google.com/settings/ads
                </a>
            </p>
            <p class="has-line-data" data-line-start="232" data-line-end="233">
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on –{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                </a>{" "}
                – for your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from
                being collected and used by Google Analytics.
            </p>
            <p class="has-line-data" data-line-start="234" data-line-end="235">
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                </a>
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="236"
                    data-line-end="238"
                >
                    <strong>Links to Other Sites</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="238" data-line-end="239">
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
            </p>
            <p class="has-line-data" data-line-start="240" data-line-end="241">
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="242"
                    data-line-end="244"
                >
                    <strong>Children's Privacy</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="244" data-line-end="245">
                Our Services are not intended for use by children under the age
                of 13 (&quot; <strong>Children</strong>&quot;).
            </p>
            <p class="has-line-data" data-line-start="246" data-line-end="247">
                We do not knowingly collect personally identifiable information
                from Children under 13. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="248"
                    data-line-end="250"
                >
                    <strong>Changes to This Privacy Policy</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="250" data-line-end="251">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p>
            <p class="has-line-data" data-line-start="252" data-line-end="253">
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                &quot;effective date&quot; at the top of this Privacy Policy.
            </p>
            <p class="has-line-data" data-line-start="254" data-line-end="255">
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
            </p>
            <ol>
                <li
                    class="has-line-data"
                    data-line-start="256"
                    data-line-end="258"
                >
                    <strong>Contact Us</strong>
                </li>
            </ol>
            <p class="has-line-data" data-line-start="258" data-line-end="259">
                If you have any questions about this Privacy Policy, please
                contact us:
            </p>
            <p class="has-line-data" data-line-start="260" data-line-end="261">
                By email:{" "}
                <a href="mailto:elixirlabsorg@gmail.com">
                    elixirlabsorg@gmail.com
                </a>
                .
            </p>
        </>
    );
}

export default Privacy;
